import React, {Suspense } from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';
import { useIsAuthenticated} from "react-auth-kit";

const Login = React.lazy(() => import('./pages/Auth/Login'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Raports = React.lazy(() => import('./pages/Site/Rapoarte'));
const SiteList = React.lazy(() => import('./pages/Site/SiteList'));
const PriceList = React.lazy(() => import('./pages/Site/PriceList'));
const TotalPayList = React.lazy(() => import('./pages/Site/TotalPayList'));
const Denied = React.lazy(()=> import('./pages/Auth/Denied'));
const NotFound = React.lazy(() => import('./pages/Site/NotFound'));

function App() {

  const RequireAuth = ({ children }) => {
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated()) {
      return <Navigate to="/acces-denied" />;
    }
  
    return <>{children}</>;
  };

  return (
      <Suspense fallback={
        <div style={{width:'100%',height:'100vh',backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <svg version="1.1" id="loader-1" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
              <path opacity="0.2" fill="#4d61fc" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
              <path fill="#4d61fc" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                C22.32,8.481,24.301,9.057,26.013,10.047z">
              <animateTransform attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="0.5s"
                repeatCount="indefinite"
              />
              </path>
          </svg>
        </div>
    }>
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/" element={
          <RequireAuth loginPath='/acces-denied'>
            <Dashboard/>
          </RequireAuth>
        }/>
        <Route path="/lista-magazine" element={
          <RequireAuth loginPath='/acces-denied'>
            <SiteList/>
          </RequireAuth>
        }/>
        <Route path="/lista-preturi" element={
          <RequireAuth loginPath='/acces-denied'>
            <PriceList/>
          </RequireAuth>
        }/>
        <Route path="/raportlunar" element={
          <RequireAuth loginPath='/acces-denied'>
            <TotalPayList/>
          </RequireAuth>
        }/>
        <Route path="/requests" element={
          <RequireAuth loginPath='/acces-denied'>
            <Raports/>
          </RequireAuth>
        }/>
        <Route path="/login/:token" element={<Login/>}/>
        <Route path="/acces-denied" element={<Denied/>}/>
        <Route path="/login" element={<Denied/>}/>
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </Suspense>
  );
}

export default App;
